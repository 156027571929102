import currentTypes from "@common/js/translations/fr/api/current-types"
export default {
  "equipment-type-select": {
    title: "Sélectionner matériel",
    subtitle:
      "Vous n'avez pas encore créé de cahier de charges. Commencez par sélectionner un modèle type ou démarrez à partir d'un nouveau cahier de charges vide.",
    "want-to-purchase": "Besoin de commander du matériel ?",
    panel: {
      name: "Modules photovoltaïques",
      description: "La liste de tous les panneaux solaires sur votre compte.",
    },
    battery: {
      name: "Batteries",
      description:
        "La liste de toutes les batteries pour stockage photovoltaïque sur votre compte, en lithium et autre.",
    },
    "service-panel": {
      name: "Tableaux électriques",
      description:
        "La liste de tous les tableaux, boîtiers et coffrets électriques sur votre compte.",
    },
    "combiner-box": {
      name: "Boîtes de combinaison",
      description:
        "La liste de toutes les boîtes de combinaison sur votre compte.",
    },
    disconnect: {
      name: "Coffrets AC/DC",
      description:
        "La liste de tous coffrets de protection, AC ou DC, sur votre compte.",
    },
    inverter: {
      name: "Onduleurs",
      description:
        "La liste de tous les onduleurs chaines et micro-onduleurs sur votre compte.",
    },
    "mounting-system": {
      name: "Systèmes de fixation",
      description:
        "La liste de tous les systèmes de fixation et de montage sur votre compte.",
    },
    "dc-optimisers": {
      name: "Optimiseurs DC",
      description:
        "La liste de tous les convertisseurs DC/DC de puissance pour photovoltaïque sur votre compte.",
    },
    "load-center": {
      name: "Disjoncteurs",
      description:
        "La liste de tous disjoncteurs, généraux et différentiels, et interrupteurs sur votre compte.",
    },
    workwear: {
      name: "Tenues pour travaux électriques",
      description:
        "La liste de vêtements de travail pour éléctriciens sur votre compte.",
    },
    accessories: {
      name: "Accessoires",
      description:
        "La liste des autres équipements pour le photovoltaïque sur votre compte.",
    },
    tiles: {
      name: "Tuiles solaires",
      description:
        "La liste des tuiles photovoltaïques, assurant l'étanchéité, sur votre compte.",
    },
    "thermal-sensors": {
      name: "Panneaux thermiques",
      description:
        "La liste des panneaux thermiques, assurant l'étanchéité, sur votre compte.",
    },
  },
  "inverter-configuration-form": {
    "restrict-output-power-max": {
      label: "Appliquez bridage aux onduleurs ?",
      input: "Bridage par onduleur",
      tooltip:
        "Veuillez télécharger le certificat de bridage de la puissance d'injection dans l'espace documents",
    },
  },
  preview: {
    edit: "Modifier fiche technique",
    dimensions: "Dimensions",
    overview: "Aperçu",
    electrical: "Électrique",
    mechanical: "Mécanique",
    panel: {
      tabs: {
        electrical: "Électrique",
        temperature: "Temperature",
        mechanical: "Mécanique",
        misc: "Autre",
      },
      type: "Type de cellule",
      "rating-stc": "Wc (conditions d'essai standard)",
      "rating-ptc": "Wc (condition d'essai PVUSA)",
      "tolerance-pmax-plus": "Tolérance pmax plus",
      "tolerance-pmax-minus": "Tolérance pmax minus",
      "voltage-at-open-circuit": "Tension en circuit ouvert (voc)",
      "open-circuit-current": "Courant de court-circuit (Isc)",
      vmp: "Tension au point de puissance maximale",
      imp: "Courant à la puissance maximale",
      efficiency: "Efficacité",
      "max-system-voltage-us": "Tension maximale du système (us)",
      "max-system-voltage-eu": "Tension maximale du système (eu)",
      noct: "La température nominale de la cellule",
      "temp-coeff-pmax": "Coefficient de température à puissance max",
      "temp-coeff-voc": "Coefficient de température tension en circuit ouvert",
      "temp-coeff-isc": "Coefficient de température courant de court-circuit",
      "circuit-model-status": "État du modèle de circuit",
      "operating-temp-min": "Température d'utilisation minimal",
      "operating-temp-max": "Température d'utilisation maximal",
      "static-load-wind": "Charge statique de vent",
      "static-load-snow": "Charge statique neige",
      "static-load": "Charge statique",
      "connector-type": "Type de connecteur",
      "work-warranty": "Garantie de fonctionnement",
      "product-warranty": "Garantie du produit",
      "frame-type": "Type de cadre",
      "frame-color": "Couleur du cadre",
      "backsheet-color": "Couleur du dos",
      "cell-quantity": "Quantité de cellules",
      "cell-string-configuration": "Configuration des chaînes de cellules",
      "cell-string-configuration-available":
        "Configuration des chaînes de cellules disponible",
      "psi-rating": "Inspection avant expédition",
      cost: "Coût",
      mlpe: "électronique de puissance au niveau du module",
      "carbon-footprint-compliance-certificate":
        "Certificat de bilan carbone du panneau solaire inférieur à 550 CO2e/kWc",
      description: "Description",
      width: "Largeur",
      height: "Hauteur",
      weight: "Poids",
      length: "Longueur",
      attachments: "Documents techniques",
      download: "Télécharger",
      select: "Sélectionner",
    },

    inverter: {
      "grid-connection-tooltip":
        "La puissance de sortie maximale de(s) onduleur(s) en injection (en kVA) est utilisée pour la puissance de raccordement.",
      tabs: {
        input: "Entrée",
        output: "Sortie",
        mechanical: "Mécanique",
        temperature: "Temperature",
        storage: "Stockage",
        harmonics: "Courant Harmonique",
      },
      "edit-certificate": "Modifier fiche certificat",
      "has-global-mppt": "A un suivi global du point de puissance maximum",
      "is-micro-inverter": "Micro onduleur ?",
      "is-hd-wave": "Utilise Hd wave",
      "efficiency-cec":
        "Efficacité pondérée de la CEC (California Energy Commission)",
      "is-synergy": "Utilise la technologie synergy",
      "is-integrated": "Est intégré ",
      "is-custom": "Est personnalisé",
      "input-short-circuitCurrent-max": "Entrer courant de court-circuit max",
      "efficiency-european": "Efficacité européenne",
      "efficiency-max": "Efficacité maximal",
      enclosure: "Boîtier",
      hasACDisconnect: "Transforme l'electricité en courant alternatif",
      hasDCDisconnect: "Transforme l'electricité en courant continu",
      "battery-charger": "Charge la batterie",
      cost: "coût",
      inputVoltageMin: "Tension d'entrer min",
      inputVoltageMax: "Tension d'entrer max",
      inputVoltageNominal: "Tension d'entrer nominal",
      "inverter-type": "Type d'onduleur",
      mpptOperatingVoltageMax: "Suivie de puissance max",
      mpptOperatingVoltageMin: "Suivie de puissance min",
      mpptRatedPowerVoltageMax: "Suivie de puissance nominal max",
      mpptRatedPowerVoltageMin: "Suivie de puissance nominal min",
      numMPPT: "Nombre de MPPT",
      numPhases: "Nombre de phases",
      numPhysicalDCInputs: "Nombre d'entrées physiques en courant continu ",
      operatingTempMax: "Temps de fontionnement max",
      operatingTempMin: "Temps de fonctionnement min",
      outputCurrentMax: "Courant de sortie max",
      outputPowerMax: "Puissance de sortie max",
      outputVoltageMax: "Tension de sortie max",
      outputVoltageMin: "Tension de sortie min",
      outputVoltageNominal: "Tension de sortie nominal",
      ratedPower: "Puissance nominal",
      startVoltage: "Tension de démarrage",
      stcInputPowerMax: "Tension stc max",
      synergyNumUnits: "Nombre d'unités synergique",
      usableInputCurrentMax: "Courant d'entrée utilisable max",
      usableInputPowerMax: "Puissance d'entrer utilisable max",
      useSafetyFactor: "Utilise le facteur de sécurité",
      "dc-inputs": "Entrée en courant continu",
      disconnect: "",
      numberPhysicalDCInputs: "Nombre d'entrée courant continue physique ",
      "input-current-max": "Courant d'entrée max",
      "input-power-max": "Puissance d'entrée max",
      mpptVoltageMax: "Tracker de tension max ",
      mpptVoltageMin: "Tracker de tension min",
      outputVoltage: "Tension de sortie ",
      phases: "Nombre de phases",
      supportedComponents: "Composants pris en charge",
      ratedPowerShortDuration: "Puissance nominale Courte durée",
      ratedPowerBackup: "Puissance nominale de la batterie de secours",
      ratedPowerBackupShortDuration:
        "Puissance nominale de la batterie de secours sur une courte durée",
      ratedPowerACCharge: "Puissance nominale en Chargement AC",
      ratedPowerACDischarge: "Puissance nominale en décharge AC",
      efficiencyRoundtrip:
        "Pourcentage d'électricité stockée qui est récupérée par la suite",
      storageCapacityMin: "Capacité minimale de la batterie connectée",
      storageCapacityMax: "Capacité maximale de la batterie connectée",
      storageVoltageMin: "Tension minimale de la batterie connectée",
      storageVoltageMax: "Tension maximale de la batterie connectée",

      impedenceRInOhm: "Valeur R en Impédances à 175 Hz (en Ω)",
      impedenceRXInOhm: "Valeur X en Impédances à 175 Hz (en Ω)",
      impedenceIp: "Impedance a 175Hz - valeur du courant crête maximal (Ip)",
      impedenceIk:
        "Impedance a 175Hz - courant de court-circuit symétrie initial (Ik'')",
      harmonics: {
        rank: "Rang",
        value: "Courant Harmonique - pourcentage de In par rang",
        empty: "Harmoniques indisponibles pour ce modèle d’onduleur",
      },
      certificate: "Certificat de l'onduleur",

      description: "Description",
      width: "Largeur",
      height: "Hauteur",
      weight: "Poids",
      length: "Longueur",
      attachments: "Documents techniques",
      download: "Télécharger",
      select: "Sélectionner",
      "mppt-ports": {
        title: "Port MPPT",
        "add-port": "Ajouter un port",
        empty: {
          title: "Aucun port MPPT ajouté",
          description: "Commencez par ajouter un nouveau port MPPT",
          button: "Nouveau port MPPT",
        },
        create: {
          title: "Ajouter un port MPPT",
          description:
            "Remplissez les détails ci-dessous pour ajouter un port MPPT",
        },
        edit: {
          title: "Modifier un port MPPT",
          description:
            "Remplissez les détails ci-dessous pour mettre à jour votre port MPPT",
        },
        delete: {
          title: "Supprimer le port MPPT ?",
          description: "Cliquer sur 'Supprimer' pour confirmer.",
        },
      },
    },
    "mounting-system": {
      description: "Description",
      width: "Largeur",
      height: "Hauteur",
      weight: "Poids",
      length: "Longueur",
      attachments: "Documents techniques",
      download: "Télécharger",
      select: "Sélectionner",
    },
    battery: {
      tabs: {
        electrical: "Électrique",
        operational: "Statistiques opérationnelles",
      },
      type: "Type",
      types: {
        LITHIUM_IRON_PHOSPHATE: "Lithium Métal",
        LEAD_ACID: "Plomb-Acide",
        LITHIUM_AIR: "Lithium-Air",
        NICKEL_METAL_HYDRIDE: "Nickel-Métal Hydrure",
        LITHIUM_POLYMER: "Lithium-Polymère",
      },
      "is-grounded": "Dans le sol",
      "capacity-nominal": "Capacité nominale",
      "capacity-usable": "Capacité utilisable",
      "depth-of-discharge": "Profondeur de décharge (DOD)",
      "charge-voltage-min": "Tension de charge minimal",
      "charge-voltage-max": "Tension de charge maximal",
      "discharge-voltage-min": "Tension de décharge minimal",
      "discharge-voltage-max": "Tension de décharge maximal",
      "charge-rate-max": "Taux de charge maximal",
      "discharge-rate-max": "Débit de décharge maximal",
      "efficiency-round-trip": "Efficacité retour (roundtrip)",
      "voltage-nominal": "Tension nominale",
      "operating-temp-min": "Température d'utilisation minimal",
      "operating-temp-max": "Température d'utilisation maximal",
      "suggested-temp-min": "Température conseillée minimal",
      "suggested-temp-max": "Température conseillée maximal",
      description: "Description",
      width: "Largeur",
      height: "Hauteur",
      weight: "Poids",
      length: "Longueur",
      attachments: "Documents techniques",
      download: "Télécharger",
      select: "Sélectionner",
    },
    "service-panel": {
      "panel-type": "Type de panneau",
      phases: "phases",
      "bus-rating": "Busrating",
      "breaker-rating": "Courant continu max supporter par le disjoncteur ",
      "nema-rating": "Indice NEMA",
      description: "Description",
      width: "Largeur",
      height: "Hauteur",
      weight: "Poids",
      length: "Longueur",
      attachments: "Documents techniques",
      download: "Télécharger",
      select: "Sélectionner",
    },
    "load-center": {
      type: "Type",
      inputs: "Entrés",
      "nema-rating": "Indice NEMA",
      "bus-rating": "Puissance des barres",
      phases: "Nombre de phases",
      "main-breaker-rating": "Disjoncteur principal entrant",
      description: "Description",
      width: "Largeur",
      height: "Hauteur",
      weight: "Poids",
      length: "Longueur",
      attachments: "Documents techniques",
      download: "Télécharger",
      select: "Sélectionner",
    },
    "dc-optimiser": {
      efficiency: "Efficacité",
      "efficiency-max": "Efficacité maximal",
      "has-global-mppt": "A un suivi global du point de puissance maximum",
      "input-short-circuit-current-max":
        "Courant de court-circuit d'entrée maximal",
      "input-voltage-min": "Tension d'entrée minimal",
      "input-voltage-max": "Tension d'entrée maximal",
      "is-integrated": "Est intégré ",
      "is-long-string": "Est en chaîne longue",
      "mppt-operating-voltage-max": "Tension de fonctionnement mppt maximal",
      "mppt-operating-voltage-min ": "Tension de fonctionnement mppt minimal",
      "num-inputs": "Nombre d'entrées",
      "operating-model": "Modèle de fonctionnement",
      "operating-temp-min": "Température d'utilisation minimal",
      "operating-temp-max": "Température d'utilisation maximal",
      "output-current-max": "Courant de sortie maximal",
      "output-voltage-max": "Tension de sortie maximal",
      "stc-input-power-max": "Puissance d'entrée stc max",
      "system-voltage-max": "Tension maximale du système",
      "usable-input-current-max": "Courant d'entrée utilisable maximum",
      "uses-selective-optimization": "Utilise l'optimisation sélective",
      "max-input-voltage": "Tension d'entrée maximale",
      "max-input-current": "Courant d'entrée maximal",
      "max-output-current": "Courant de sortie maximal",
      "max-system-voltage": "Tension maximale du système",
      "mppt-range-min": "Plage du mppt minimal",
      "mppt-range-max": "Plage du mppt maximal",
      "related-input-power": "Puissance d'entrée associée",
      "selective-optimization": "Optimisation sélective",
      "optimization-behaviour": "Optimisation du comportement",
      description: "Description",
      width: "Largeur",
      height: "Hauteur",
      weight: "Poids",
      length: "Longueur",
      attachments: "Documents techniques",
      download: "Télécharger",
      select: "Sélectionner",
    },
    "combiner-box": {
      "voltage-rating": "Tension nominale",
      "fuse-rating": "Puissance du fusible",
      inputs: "Entrés",
      "nema-rating": "Indice NEMA",
      description: "Description",
      width: "Largeur",
      height: "Hauteur",
      weight: "Poids",
      length: "Longueur",
      attachments: "Documents techniques",
      download: "Télécharger",
      select: "Sélectionner",
    },
    disconnect: {
      poles: "Poles",
      fused: "Fusible",
      "voltage-rating": "Tension nominale",
      "current-rating": "Courant nominale",
      "fuse-rating": "Puissance du fusible",
      "nema-rating": "Classification Nema ",
      description: "Description",
      width: "Largeur",
      height: "Hauteur",
      weight: "Poids",
      length: "Longueur",
      attachments: "Documents techniques",
      "current-types": currentTypes,
      "current-type": "Type de courant",
    },
    tiles: {
      "weight-in-kg-per-m2": "Poids en kg par m2",
      style: "Style",
      "roof-pitch": "Inclinaison du toit",
      "glass-width-in-mm": "Largeur du verre en mm",
      "rating-stc": "Wc (conditions d'essai standard)",
      "rating-ptc": "Wc (condition d'essai PVUSA)",
      "tolerance-pmax-plus": "Tolérance pmax plus",
      "tolerance-pmax-minus": "Tolérance pmax minus",
      "voltage-at-open-circuit": "Tension en circuit ouvert (voc)",
      "open-circuit-current": "Courant de court-circuit (Isc)",
      vmp: "Tension au point de puissance maximale",
      imp: "Courant à la puissance maximale",
      efficiency: "Efficacité",
      "max-system-voltage-us": "Tension maximale du système (us)",
      "max-system-voltage-eu": "Tension maximale du système (eu)",
      noct: "La température nominale de la cellule",
      "temp-coeff-pmax": "Coefficient de température à puissance max",
      "temp-coeff-voc": "Coefficient de température tension en circuit ouvert",
      "temp-coeff-isc": "Coefficient de température courant de court-circuit",
      "circuit-model-status": "état du modèle de circuit",
      "operating-temp-min": "Température d'utilisation minimal",
      "operating-temp-max": "Température d'utilisation maximal",
      "static-load-wind": "Charge statique de vent",
      "static-load-snow": "Charge statique neige",
      "connector-type": "Type de connecteur",
      "work-warranty": "Garantie de fonctionnement",
      "product-warranty": "Garantie du produit",
      "frame-type": "Type de cadre",
      "frame-color": "Couleur du cadre",
      "backsheet-color": "Couleur du dos",
      "cell-quantity": "Quantité de cellule",
      "cell-string-configuration": "Configuration des chaînes de cellules",
      "cell-string-configuration-available":
        "Configuration des chaînes de cellules disponible",
      "psi-rating": "Inspection avant expédition",
      cost: "Coût",
      mlpe: "électronique de puissance au niveau du module",

      description: "Description",
      width: "Largeur",
      height: "Hauteur",
      weight: "Poids",
      length: "Longueur",
      attachments: "Documents techniques",
      download: "Télécharger",
      select: "Sélectionner",
    },
    accessories: {
      description: "Description",
      width: "Largeur",
      height: "Hauteur",
      weight: "Poids",
      length: "Longueur",
      attachments: "Documents techniques",
      download: "Télécharger",
      select: "Sélectionner",
    },
  },
  "equipment-search": {
    manufacturer: "Fabricant",
    model: "Modèle",
    "model-name": "Nom du modèle",
    "recent-searches": "Recherches récentes",
    "missing-hardware-tooltip": "Manque un modèle de la base de données?",
    "write-few": "Rédigez quelques phrases sur l'équipement.",
    empty: {
      title: "Aucun équipement trouvé",
      subtitle: "Nous n'avons rien trouvé avec ce terme. Veuillez réessayer.",
    },
    placeholders: {
      "manufacturer-brand": "Marque du fabricant",
      "model-name": "Nom du modèle",
    },
    select: "Sélectionner",
  },
}
